import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src3866267250/src/sense-us.app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src3866267250/src/sense-us.app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src3866267250/src/sense-us.app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src3866267250/src/sense-us.app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src3866267250/src/sense-us.app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src3866267250/src/sense-us.app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/codebuild/output/src3866267250/src/sense-us.app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/codebuild/output/src3866267250/src/sense-us.app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src3866267250/src/sense-us.app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src3866267250/src/sense-us.app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import amplify_Nj8AYDITi6 from "/codebuild/output/src3866267250/src/sense-us.app/plugins/amplify.js";
import disableIosAutoZoom_client_LDv2nRZyr9 from "/codebuild/output/src3866267250/src/sense-us.app/plugins/disableIosAutoZoom.client.js";
import iubenda_client_O6BVmh6yEB from "/codebuild/output/src3866267250/src/sense-us.app/plugins/iubenda.client.js";
import loadI18nMessages_client_8F1oNjusSu from "/codebuild/output/src3866267250/src/sense-us.app/plugins/loadI18nMessages.client.js";
import sentry_client_GoGQuZo4Et from "/codebuild/output/src3866267250/src/sense-us.app/plugins/sentry.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  amplify_Nj8AYDITi6,
  disableIosAutoZoom_client_LDv2nRZyr9,
  iubenda_client_O6BVmh6yEB,
  loadI18nMessages_client_8F1oNjusSu,
  sentry_client_GoGQuZo4Et
]