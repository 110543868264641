import { default as index0e6Y9PS00vMeta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/[roundId]/index.vue?macro=true";
import { default as resultsDtqAp6DvNUMeta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/[roundId]/results.vue?macro=true";
import { default as indexi01aQ7xv1KMeta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/index.vue?macro=true";
import { default as none8Blee4vDIDMeta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/none.vue?macro=true";
import { default as profileaVnCcbN0RZMeta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/profile.vue?macro=true";
import { default as imageduCzthtLZPMeta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/admin/answer/[answerId]/image.vue?macro=true";
import { default as index1Tf43jjQ0LMeta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/admin/index.vue?macro=true";
import { default as aggregate8MehFgOh9oMeta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue?macro=true";
import { default as indexpg8vPHWXQaMeta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/index.vue?macro=true";
import { default as profileBhhl9oeh1mMeta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/profile.vue?macro=true";
import { default as resultsGq4zPVZEB5Meta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/results.vue?macro=true";
import { default as _91roundId_93mwSl2WzS7PMeta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue?macro=true";
import { default as create7pfuIMfYEvMeta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue?macro=true";
import { default as _91userId_93Xk9RgwkCyIMeta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue?macro=true";
import { default as indexyXyZk2HuB5Meta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue?macro=true";
import { default as createjDMhZk3zMyMeta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/create.vue?macro=true";
import { default as index6KutQ7DhkIMeta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/index.vue?macro=true";
import { default as loginUqdgZn1UeFMeta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/login.vue?macro=true";
import { default as registerEjZnBsswbFMeta } from "/codebuild/output/src3866267250/src/sense-us.app/pages/register.vue?macro=true";
export default [
  {
    name: index0e6Y9PS00vMeta?.name ?? "projectId-roundId___en",
    path: index0e6Y9PS00vMeta?.path ?? "/:projectId()/:roundId()",
    meta: index0e6Y9PS00vMeta || {},
    alias: index0e6Y9PS00vMeta?.alias || [],
    redirect: index0e6Y9PS00vMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: index0e6Y9PS00vMeta?.name ?? "projectId-roundId___nl",
    path: index0e6Y9PS00vMeta?.path ?? "/nl/:projectId()/:roundId()",
    meta: index0e6Y9PS00vMeta || {},
    alias: index0e6Y9PS00vMeta?.alias || [],
    redirect: index0e6Y9PS00vMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: index0e6Y9PS00vMeta?.name ?? "projectId-roundId___de",
    path: index0e6Y9PS00vMeta?.path ?? "/de/:projectId()/:roundId()",
    meta: index0e6Y9PS00vMeta || {},
    alias: index0e6Y9PS00vMeta?.alias || [],
    redirect: index0e6Y9PS00vMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: index0e6Y9PS00vMeta?.name ?? "projectId-roundId___es",
    path: index0e6Y9PS00vMeta?.path ?? "/es/:projectId()/:roundId()",
    meta: index0e6Y9PS00vMeta || {},
    alias: index0e6Y9PS00vMeta?.alias || [],
    redirect: index0e6Y9PS00vMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: resultsDtqAp6DvNUMeta?.name ?? "projectId-roundId-results___en",
    path: resultsDtqAp6DvNUMeta?.path ?? "/:projectId()/:roundId()/results",
    meta: resultsDtqAp6DvNUMeta || {},
    alias: resultsDtqAp6DvNUMeta?.alias || [],
    redirect: resultsDtqAp6DvNUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsDtqAp6DvNUMeta?.name ?? "projectId-roundId-results___nl",
    path: resultsDtqAp6DvNUMeta?.path ?? "/nl/:projectId()/:roundId()/results",
    meta: resultsDtqAp6DvNUMeta || {},
    alias: resultsDtqAp6DvNUMeta?.alias || [],
    redirect: resultsDtqAp6DvNUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsDtqAp6DvNUMeta?.name ?? "projectId-roundId-results___de",
    path: resultsDtqAp6DvNUMeta?.path ?? "/de/:projectId()/:roundId()/results",
    meta: resultsDtqAp6DvNUMeta || {},
    alias: resultsDtqAp6DvNUMeta?.alias || [],
    redirect: resultsDtqAp6DvNUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsDtqAp6DvNUMeta?.name ?? "projectId-roundId-results___es",
    path: resultsDtqAp6DvNUMeta?.path ?? "/es/:projectId()/:roundId()/results",
    meta: resultsDtqAp6DvNUMeta || {},
    alias: resultsDtqAp6DvNUMeta?.alias || [],
    redirect: resultsDtqAp6DvNUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: indexi01aQ7xv1KMeta?.name ?? "projectId___en",
    path: indexi01aQ7xv1KMeta?.path ?? "/:projectId()",
    meta: indexi01aQ7xv1KMeta || {},
    alias: indexi01aQ7xv1KMeta?.alias || [],
    redirect: indexi01aQ7xv1KMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexi01aQ7xv1KMeta?.name ?? "projectId___nl",
    path: indexi01aQ7xv1KMeta?.path ?? "/nl/:projectId()",
    meta: indexi01aQ7xv1KMeta || {},
    alias: indexi01aQ7xv1KMeta?.alias || [],
    redirect: indexi01aQ7xv1KMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexi01aQ7xv1KMeta?.name ?? "projectId___de",
    path: indexi01aQ7xv1KMeta?.path ?? "/de/:projectId()",
    meta: indexi01aQ7xv1KMeta || {},
    alias: indexi01aQ7xv1KMeta?.alias || [],
    redirect: indexi01aQ7xv1KMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexi01aQ7xv1KMeta?.name ?? "projectId___es",
    path: indexi01aQ7xv1KMeta?.path ?? "/es/:projectId()",
    meta: indexi01aQ7xv1KMeta || {},
    alias: indexi01aQ7xv1KMeta?.alias || [],
    redirect: indexi01aQ7xv1KMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: none8Blee4vDIDMeta?.name ?? "projectId-none___en",
    path: none8Blee4vDIDMeta?.path ?? "/:projectId()/none",
    meta: none8Blee4vDIDMeta || {},
    alias: none8Blee4vDIDMeta?.alias || [],
    redirect: none8Blee4vDIDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: none8Blee4vDIDMeta?.name ?? "projectId-none___nl",
    path: none8Blee4vDIDMeta?.path ?? "/nl/:projectId()/none",
    meta: none8Blee4vDIDMeta || {},
    alias: none8Blee4vDIDMeta?.alias || [],
    redirect: none8Blee4vDIDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: none8Blee4vDIDMeta?.name ?? "projectId-none___de",
    path: none8Blee4vDIDMeta?.path ?? "/de/:projectId()/none",
    meta: none8Blee4vDIDMeta || {},
    alias: none8Blee4vDIDMeta?.alias || [],
    redirect: none8Blee4vDIDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: none8Blee4vDIDMeta?.name ?? "projectId-none___es",
    path: none8Blee4vDIDMeta?.path ?? "/es/:projectId()/none",
    meta: none8Blee4vDIDMeta || {},
    alias: none8Blee4vDIDMeta?.alias || [],
    redirect: none8Blee4vDIDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: profileaVnCcbN0RZMeta?.name ?? "projectId-profile___en",
    path: profileaVnCcbN0RZMeta?.path ?? "/:projectId()/profile",
    meta: profileaVnCcbN0RZMeta || {},
    alias: profileaVnCcbN0RZMeta?.alias || [],
    redirect: profileaVnCcbN0RZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileaVnCcbN0RZMeta?.name ?? "projectId-profile___nl",
    path: profileaVnCcbN0RZMeta?.path ?? "/nl/:projectId()/profile",
    meta: profileaVnCcbN0RZMeta || {},
    alias: profileaVnCcbN0RZMeta?.alias || [],
    redirect: profileaVnCcbN0RZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileaVnCcbN0RZMeta?.name ?? "projectId-profile___de",
    path: profileaVnCcbN0RZMeta?.path ?? "/de/:projectId()/profile",
    meta: profileaVnCcbN0RZMeta || {},
    alias: profileaVnCcbN0RZMeta?.alias || [],
    redirect: profileaVnCcbN0RZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileaVnCcbN0RZMeta?.name ?? "projectId-profile___es",
    path: profileaVnCcbN0RZMeta?.path ?? "/es/:projectId()/profile",
    meta: profileaVnCcbN0RZMeta || {},
    alias: profileaVnCcbN0RZMeta?.alias || [],
    redirect: profileaVnCcbN0RZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: imageduCzthtLZPMeta?.name ?? "admin-answer-answerId-image___en",
    path: imageduCzthtLZPMeta?.path ?? "/admin/answer/:answerId()/image",
    meta: imageduCzthtLZPMeta || {},
    alias: imageduCzthtLZPMeta?.alias || [],
    redirect: imageduCzthtLZPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: imageduCzthtLZPMeta?.name ?? "admin-answer-answerId-image___nl",
    path: imageduCzthtLZPMeta?.path ?? "/nl/admin/answer/:answerId()/image",
    meta: imageduCzthtLZPMeta || {},
    alias: imageduCzthtLZPMeta?.alias || [],
    redirect: imageduCzthtLZPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: imageduCzthtLZPMeta?.name ?? "admin-answer-answerId-image___de",
    path: imageduCzthtLZPMeta?.path ?? "/de/admin/answer/:answerId()/image",
    meta: imageduCzthtLZPMeta || {},
    alias: imageduCzthtLZPMeta?.alias || [],
    redirect: imageduCzthtLZPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: imageduCzthtLZPMeta?.name ?? "admin-answer-answerId-image___es",
    path: imageduCzthtLZPMeta?.path ?? "/es/admin/answer/:answerId()/image",
    meta: imageduCzthtLZPMeta || {},
    alias: imageduCzthtLZPMeta?.alias || [],
    redirect: imageduCzthtLZPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: index1Tf43jjQ0LMeta?.name ?? "admin___en",
    path: index1Tf43jjQ0LMeta?.path ?? "/admin",
    meta: index1Tf43jjQ0LMeta || {},
    alias: index1Tf43jjQ0LMeta?.alias || [],
    redirect: index1Tf43jjQ0LMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: index1Tf43jjQ0LMeta?.name ?? "admin___nl",
    path: index1Tf43jjQ0LMeta?.path ?? "/nl/admin",
    meta: index1Tf43jjQ0LMeta || {},
    alias: index1Tf43jjQ0LMeta?.alias || [],
    redirect: index1Tf43jjQ0LMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: index1Tf43jjQ0LMeta?.name ?? "admin___de",
    path: index1Tf43jjQ0LMeta?.path ?? "/de/admin",
    meta: index1Tf43jjQ0LMeta || {},
    alias: index1Tf43jjQ0LMeta?.alias || [],
    redirect: index1Tf43jjQ0LMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: index1Tf43jjQ0LMeta?.name ?? "admin___es",
    path: index1Tf43jjQ0LMeta?.path ?? "/es/admin",
    meta: index1Tf43jjQ0LMeta || {},
    alias: index1Tf43jjQ0LMeta?.alias || [],
    redirect: index1Tf43jjQ0LMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: aggregate8MehFgOh9oMeta?.name ?? "admin-project-projectId-aggregate___en",
    path: aggregate8MehFgOh9oMeta?.path ?? "/admin/project/:projectId()/aggregate",
    meta: aggregate8MehFgOh9oMeta || {},
    alias: aggregate8MehFgOh9oMeta?.alias || [],
    redirect: aggregate8MehFgOh9oMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: aggregate8MehFgOh9oMeta?.name ?? "admin-project-projectId-aggregate___nl",
    path: aggregate8MehFgOh9oMeta?.path ?? "/nl/admin/project/:projectId()/aggregate",
    meta: aggregate8MehFgOh9oMeta || {},
    alias: aggregate8MehFgOh9oMeta?.alias || [],
    redirect: aggregate8MehFgOh9oMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: aggregate8MehFgOh9oMeta?.name ?? "admin-project-projectId-aggregate___de",
    path: aggregate8MehFgOh9oMeta?.path ?? "/de/admin/project/:projectId()/aggregate",
    meta: aggregate8MehFgOh9oMeta || {},
    alias: aggregate8MehFgOh9oMeta?.alias || [],
    redirect: aggregate8MehFgOh9oMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: aggregate8MehFgOh9oMeta?.name ?? "admin-project-projectId-aggregate___es",
    path: aggregate8MehFgOh9oMeta?.path ?? "/es/admin/project/:projectId()/aggregate",
    meta: aggregate8MehFgOh9oMeta || {},
    alias: aggregate8MehFgOh9oMeta?.alias || [],
    redirect: aggregate8MehFgOh9oMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: indexpg8vPHWXQaMeta?.name ?? "admin-project-projectId___en",
    path: indexpg8vPHWXQaMeta?.path ?? "/admin/project/:projectId()",
    meta: indexpg8vPHWXQaMeta || {},
    alias: indexpg8vPHWXQaMeta?.alias || [],
    redirect: indexpg8vPHWXQaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpg8vPHWXQaMeta?.name ?? "admin-project-projectId___nl",
    path: indexpg8vPHWXQaMeta?.path ?? "/nl/admin/project/:projectId()",
    meta: indexpg8vPHWXQaMeta || {},
    alias: indexpg8vPHWXQaMeta?.alias || [],
    redirect: indexpg8vPHWXQaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpg8vPHWXQaMeta?.name ?? "admin-project-projectId___de",
    path: indexpg8vPHWXQaMeta?.path ?? "/de/admin/project/:projectId()",
    meta: indexpg8vPHWXQaMeta || {},
    alias: indexpg8vPHWXQaMeta?.alias || [],
    redirect: indexpg8vPHWXQaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpg8vPHWXQaMeta?.name ?? "admin-project-projectId___es",
    path: indexpg8vPHWXQaMeta?.path ?? "/es/admin/project/:projectId()",
    meta: indexpg8vPHWXQaMeta || {},
    alias: indexpg8vPHWXQaMeta?.alias || [],
    redirect: indexpg8vPHWXQaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: profileBhhl9oeh1mMeta?.name ?? "admin-project-projectId-profile___en",
    path: profileBhhl9oeh1mMeta?.path ?? "/admin/project/:projectId()/profile",
    meta: profileBhhl9oeh1mMeta || {},
    alias: profileBhhl9oeh1mMeta?.alias || [],
    redirect: profileBhhl9oeh1mMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileBhhl9oeh1mMeta?.name ?? "admin-project-projectId-profile___nl",
    path: profileBhhl9oeh1mMeta?.path ?? "/nl/admin/project/:projectId()/profile",
    meta: profileBhhl9oeh1mMeta || {},
    alias: profileBhhl9oeh1mMeta?.alias || [],
    redirect: profileBhhl9oeh1mMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileBhhl9oeh1mMeta?.name ?? "admin-project-projectId-profile___de",
    path: profileBhhl9oeh1mMeta?.path ?? "/de/admin/project/:projectId()/profile",
    meta: profileBhhl9oeh1mMeta || {},
    alias: profileBhhl9oeh1mMeta?.alias || [],
    redirect: profileBhhl9oeh1mMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileBhhl9oeh1mMeta?.name ?? "admin-project-projectId-profile___es",
    path: profileBhhl9oeh1mMeta?.path ?? "/es/admin/project/:projectId()/profile",
    meta: profileBhhl9oeh1mMeta || {},
    alias: profileBhhl9oeh1mMeta?.alias || [],
    redirect: profileBhhl9oeh1mMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: resultsGq4zPVZEB5Meta?.name ?? "admin-project-projectId-results___en",
    path: resultsGq4zPVZEB5Meta?.path ?? "/admin/project/:projectId()/results",
    meta: resultsGq4zPVZEB5Meta || {},
    alias: resultsGq4zPVZEB5Meta?.alias || [],
    redirect: resultsGq4zPVZEB5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsGq4zPVZEB5Meta?.name ?? "admin-project-projectId-results___nl",
    path: resultsGq4zPVZEB5Meta?.path ?? "/nl/admin/project/:projectId()/results",
    meta: resultsGq4zPVZEB5Meta || {},
    alias: resultsGq4zPVZEB5Meta?.alias || [],
    redirect: resultsGq4zPVZEB5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsGq4zPVZEB5Meta?.name ?? "admin-project-projectId-results___de",
    path: resultsGq4zPVZEB5Meta?.path ?? "/de/admin/project/:projectId()/results",
    meta: resultsGq4zPVZEB5Meta || {},
    alias: resultsGq4zPVZEB5Meta?.alias || [],
    redirect: resultsGq4zPVZEB5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsGq4zPVZEB5Meta?.name ?? "admin-project-projectId-results___es",
    path: resultsGq4zPVZEB5Meta?.path ?? "/es/admin/project/:projectId()/results",
    meta: resultsGq4zPVZEB5Meta || {},
    alias: resultsGq4zPVZEB5Meta?.alias || [],
    redirect: resultsGq4zPVZEB5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93mwSl2WzS7PMeta?.name ?? "admin-project-projectId-round-roundId___en",
    path: _91roundId_93mwSl2WzS7PMeta?.path ?? "/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93mwSl2WzS7PMeta || {},
    alias: _91roundId_93mwSl2WzS7PMeta?.alias || [],
    redirect: _91roundId_93mwSl2WzS7PMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93mwSl2WzS7PMeta?.name ?? "admin-project-projectId-round-roundId___nl",
    path: _91roundId_93mwSl2WzS7PMeta?.path ?? "/nl/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93mwSl2WzS7PMeta || {},
    alias: _91roundId_93mwSl2WzS7PMeta?.alias || [],
    redirect: _91roundId_93mwSl2WzS7PMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93mwSl2WzS7PMeta?.name ?? "admin-project-projectId-round-roundId___de",
    path: _91roundId_93mwSl2WzS7PMeta?.path ?? "/de/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93mwSl2WzS7PMeta || {},
    alias: _91roundId_93mwSl2WzS7PMeta?.alias || [],
    redirect: _91roundId_93mwSl2WzS7PMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93mwSl2WzS7PMeta?.name ?? "admin-project-projectId-round-roundId___es",
    path: _91roundId_93mwSl2WzS7PMeta?.path ?? "/es/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93mwSl2WzS7PMeta || {},
    alias: _91roundId_93mwSl2WzS7PMeta?.alias || [],
    redirect: _91roundId_93mwSl2WzS7PMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: create7pfuIMfYEvMeta?.name ?? "admin-project-projectId-round-create___en",
    path: create7pfuIMfYEvMeta?.path ?? "/admin/project/:projectId()/round/create",
    meta: create7pfuIMfYEvMeta || {},
    alias: create7pfuIMfYEvMeta?.alias || [],
    redirect: create7pfuIMfYEvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: create7pfuIMfYEvMeta?.name ?? "admin-project-projectId-round-create___nl",
    path: create7pfuIMfYEvMeta?.path ?? "/nl/admin/project/:projectId()/round/create",
    meta: create7pfuIMfYEvMeta || {},
    alias: create7pfuIMfYEvMeta?.alias || [],
    redirect: create7pfuIMfYEvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: create7pfuIMfYEvMeta?.name ?? "admin-project-projectId-round-create___de",
    path: create7pfuIMfYEvMeta?.path ?? "/de/admin/project/:projectId()/round/create",
    meta: create7pfuIMfYEvMeta || {},
    alias: create7pfuIMfYEvMeta?.alias || [],
    redirect: create7pfuIMfYEvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: create7pfuIMfYEvMeta?.name ?? "admin-project-projectId-round-create___es",
    path: create7pfuIMfYEvMeta?.path ?? "/es/admin/project/:projectId()/round/create",
    meta: create7pfuIMfYEvMeta || {},
    alias: create7pfuIMfYEvMeta?.alias || [],
    redirect: create7pfuIMfYEvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: _91userId_93Xk9RgwkCyIMeta?.name ?? "admin-project-projectId-users-userId___en",
    path: _91userId_93Xk9RgwkCyIMeta?.path ?? "/admin/project/:projectId()/users/:userId()",
    meta: _91userId_93Xk9RgwkCyIMeta || {},
    alias: _91userId_93Xk9RgwkCyIMeta?.alias || [],
    redirect: _91userId_93Xk9RgwkCyIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_93Xk9RgwkCyIMeta?.name ?? "admin-project-projectId-users-userId___nl",
    path: _91userId_93Xk9RgwkCyIMeta?.path ?? "/nl/admin/project/:projectId()/users/:userId()",
    meta: _91userId_93Xk9RgwkCyIMeta || {},
    alias: _91userId_93Xk9RgwkCyIMeta?.alias || [],
    redirect: _91userId_93Xk9RgwkCyIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_93Xk9RgwkCyIMeta?.name ?? "admin-project-projectId-users-userId___de",
    path: _91userId_93Xk9RgwkCyIMeta?.path ?? "/de/admin/project/:projectId()/users/:userId()",
    meta: _91userId_93Xk9RgwkCyIMeta || {},
    alias: _91userId_93Xk9RgwkCyIMeta?.alias || [],
    redirect: _91userId_93Xk9RgwkCyIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_93Xk9RgwkCyIMeta?.name ?? "admin-project-projectId-users-userId___es",
    path: _91userId_93Xk9RgwkCyIMeta?.path ?? "/es/admin/project/:projectId()/users/:userId()",
    meta: _91userId_93Xk9RgwkCyIMeta || {},
    alias: _91userId_93Xk9RgwkCyIMeta?.alias || [],
    redirect: _91userId_93Xk9RgwkCyIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: indexyXyZk2HuB5Meta?.name ?? "admin-project-projectId-users___en",
    path: indexyXyZk2HuB5Meta?.path ?? "/admin/project/:projectId()/users",
    meta: indexyXyZk2HuB5Meta || {},
    alias: indexyXyZk2HuB5Meta?.alias || [],
    redirect: indexyXyZk2HuB5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexyXyZk2HuB5Meta?.name ?? "admin-project-projectId-users___nl",
    path: indexyXyZk2HuB5Meta?.path ?? "/nl/admin/project/:projectId()/users",
    meta: indexyXyZk2HuB5Meta || {},
    alias: indexyXyZk2HuB5Meta?.alias || [],
    redirect: indexyXyZk2HuB5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexyXyZk2HuB5Meta?.name ?? "admin-project-projectId-users___de",
    path: indexyXyZk2HuB5Meta?.path ?? "/de/admin/project/:projectId()/users",
    meta: indexyXyZk2HuB5Meta || {},
    alias: indexyXyZk2HuB5Meta?.alias || [],
    redirect: indexyXyZk2HuB5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexyXyZk2HuB5Meta?.name ?? "admin-project-projectId-users___es",
    path: indexyXyZk2HuB5Meta?.path ?? "/es/admin/project/:projectId()/users",
    meta: indexyXyZk2HuB5Meta || {},
    alias: indexyXyZk2HuB5Meta?.alias || [],
    redirect: indexyXyZk2HuB5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: createjDMhZk3zMyMeta?.name ?? "admin-project-create___en",
    path: createjDMhZk3zMyMeta?.path ?? "/admin/project/create",
    meta: createjDMhZk3zMyMeta || {},
    alias: createjDMhZk3zMyMeta?.alias || [],
    redirect: createjDMhZk3zMyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: createjDMhZk3zMyMeta?.name ?? "admin-project-create___nl",
    path: createjDMhZk3zMyMeta?.path ?? "/nl/admin/project/create",
    meta: createjDMhZk3zMyMeta || {},
    alias: createjDMhZk3zMyMeta?.alias || [],
    redirect: createjDMhZk3zMyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: createjDMhZk3zMyMeta?.name ?? "admin-project-create___de",
    path: createjDMhZk3zMyMeta?.path ?? "/de/admin/project/create",
    meta: createjDMhZk3zMyMeta || {},
    alias: createjDMhZk3zMyMeta?.alias || [],
    redirect: createjDMhZk3zMyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: createjDMhZk3zMyMeta?.name ?? "admin-project-create___es",
    path: createjDMhZk3zMyMeta?.path ?? "/es/admin/project/create",
    meta: createjDMhZk3zMyMeta || {},
    alias: createjDMhZk3zMyMeta?.alias || [],
    redirect: createjDMhZk3zMyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: index6KutQ7DhkIMeta?.name ?? "index___en",
    path: index6KutQ7DhkIMeta?.path ?? "/",
    meta: index6KutQ7DhkIMeta || {},
    alias: index6KutQ7DhkIMeta?.alias || [],
    redirect: index6KutQ7DhkIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index6KutQ7DhkIMeta?.name ?? "index___nl",
    path: index6KutQ7DhkIMeta?.path ?? "/nl",
    meta: index6KutQ7DhkIMeta || {},
    alias: index6KutQ7DhkIMeta?.alias || [],
    redirect: index6KutQ7DhkIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index6KutQ7DhkIMeta?.name ?? "index___de",
    path: index6KutQ7DhkIMeta?.path ?? "/de",
    meta: index6KutQ7DhkIMeta || {},
    alias: index6KutQ7DhkIMeta?.alias || [],
    redirect: index6KutQ7DhkIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index6KutQ7DhkIMeta?.name ?? "index___es",
    path: index6KutQ7DhkIMeta?.path ?? "/es",
    meta: index6KutQ7DhkIMeta || {},
    alias: index6KutQ7DhkIMeta?.alias || [],
    redirect: index6KutQ7DhkIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginUqdgZn1UeFMeta?.name ?? "login___en",
    path: loginUqdgZn1UeFMeta?.path ?? "/login",
    meta: loginUqdgZn1UeFMeta || {},
    alias: loginUqdgZn1UeFMeta?.alias || [],
    redirect: loginUqdgZn1UeFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginUqdgZn1UeFMeta?.name ?? "login___nl",
    path: loginUqdgZn1UeFMeta?.path ?? "/nl/login",
    meta: loginUqdgZn1UeFMeta || {},
    alias: loginUqdgZn1UeFMeta?.alias || [],
    redirect: loginUqdgZn1UeFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginUqdgZn1UeFMeta?.name ?? "login___de",
    path: loginUqdgZn1UeFMeta?.path ?? "/de/login",
    meta: loginUqdgZn1UeFMeta || {},
    alias: loginUqdgZn1UeFMeta?.alias || [],
    redirect: loginUqdgZn1UeFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginUqdgZn1UeFMeta?.name ?? "login___es",
    path: loginUqdgZn1UeFMeta?.path ?? "/es/login",
    meta: loginUqdgZn1UeFMeta || {},
    alias: loginUqdgZn1UeFMeta?.alias || [],
    redirect: loginUqdgZn1UeFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: registerEjZnBsswbFMeta?.name ?? "register___en",
    path: registerEjZnBsswbFMeta?.path ?? "/register",
    meta: registerEjZnBsswbFMeta || {},
    alias: registerEjZnBsswbFMeta?.alias || [],
    redirect: registerEjZnBsswbFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerEjZnBsswbFMeta?.name ?? "register___nl",
    path: registerEjZnBsswbFMeta?.path ?? "/nl/register",
    meta: registerEjZnBsswbFMeta || {},
    alias: registerEjZnBsswbFMeta?.alias || [],
    redirect: registerEjZnBsswbFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerEjZnBsswbFMeta?.name ?? "register___de",
    path: registerEjZnBsswbFMeta?.path ?? "/de/register",
    meta: registerEjZnBsswbFMeta || {},
    alias: registerEjZnBsswbFMeta?.alias || [],
    redirect: registerEjZnBsswbFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerEjZnBsswbFMeta?.name ?? "register___es",
    path: registerEjZnBsswbFMeta?.path ?? "/es/register",
    meta: registerEjZnBsswbFMeta || {},
    alias: registerEjZnBsswbFMeta?.alias || [],
    redirect: registerEjZnBsswbFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3866267250/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  }
]